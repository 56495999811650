@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@-webkit-keyframes bg {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(-25%);
      transform: translateX(-25%);
    }
  }
  
  @keyframes bg {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(-25%);
      transform: translateX(-25%);
    }
  }
  
  body {
      background-color:unset !important;
  }
  .bg {
    opacity: 0.9;
    position: fixed;
    background: black;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  
  .bg div {
    position: absolute;
    height: 100%;
    width: 150%;
    background-position:center;
    top: 0px;
    left:0;
    background-size: cover;
    transition: opacity 3s ease-in-out;
    -webkit-animation: bg linear infinite 45s;
            animation: bg linear infinite 45s;
    opacity: 0;
  }
  
  .bg div.visible {
    visibility: visible;
    opacity: 1;
  }
  
  .bg div.top {
    z-index: 2;
  }
.row {
  padding-top: 1em;
}

.col {
  margin-bottom: 1em;
}

.btn-light {
  color: #0069d9;
  border-color: #0069d9;
  background-color: white;
}

.btn-light:hover {
  color: white;
  background-color: #0069d9;
}

@-webkit-keyframes slide {
  100% { -webkit-transform: translateX(0%); transform: translateX(0%) }
}

@keyframes slide {
  100% { -webkit-transform: translateX(0%); transform: translateX(0%) }
}

.btn-light:focus {
  color: white;
  background-color: #0069d9;
  border-color: #0069d9;
  box-shadow: none;
}

.disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.65;
    filter: alpha(opacity=65);
    box-shadow: none;
}

/* If thumbnail is disable */
.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: 100vh;
}

/* .is-visible {
  display: flex;
  height: 210px;
} */
