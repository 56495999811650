.row {
  padding-top: 1em;
}

.col {
  margin-bottom: 1em;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.btn-light {
  color: #0069d9;
  border-color: #0069d9;
  background-color: white;
}

.btn-light:hover {
  color: white;
  background-color: #0069d9;
}

@keyframes slide {
  100% { transform: translateX(0%) }
}

.btn-light:focus {
  color: white;
  background-color: #0069d9;
  border-color: #0069d9;
  box-shadow: none;
}

.disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.65;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none;
}

/* If thumbnail is disable */
.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: 100vh;
}

/* .is-visible {
  display: flex;
  height: 210px;
} */